import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import PrintColorFields from './print-color-fields';
import { difference, intersection, find } from 'lodash';
import { containsAtLeastOne } from '../utility';
import CustomItemSelect from './CustomItemSelect';
import CheckBoxField from './check-box-field';
import RadioButtonOption from './RadioButtonOption';
import { localGet } from '../fetch-local';
import classNames from 'classnames';

const GlobalData = window.GlobalData;

class LineItemFields extends Component {
  static propTypes = {
    name: PropTypes.string,
    index: PropTypes.number,
    quantity: PropTypes.number,
    order: PropTypes.shape({
      id: PropTypes.number.isRequired,
      isPostCheckout: PropTypes.bool.isRequired,
      formIsExposable: PropTypes.bool,
    }).isRequired,
    description: PropTypes.string,
    product_id: PropTypes.number,
    'added_to_bird_bank?': PropTypes.bool,
    color: PropTypes.object,
    product: PropTypes.object,
    art_job_id: PropTypes.number,
    bird_bank: PropTypes.bool,
    bag_tag: PropTypes.bool,
    art_submitted: PropTypes.bool,
    individual_ship: PropTypes.bool,
    individual_payment: PropTypes.bool,
    heat_tagging: PropTypes.bool,
    free_items: PropTypes.number,
    none: PropTypes.bool,
    non_commissionable: PropTypes.bool,
    pick_pack_ship: PropTypes.bool,
    poly_bag: PropTypes.bool,
    split_ship: PropTypes.bool,
    temporary_storage: PropTypes.bool,
    folding: PropTypes.bool,
    hang_tags: PropTypes.bool,
    hanging: PropTypes.bool,
    kitting: PropTypes.bool,
    marketing_inserts: PropTypes.bool,
    sewn_tags: PropTypes.bool,
    stickers: PropTypes.bool,
    tag_removal: PropTypes.bool,
    art_job: PropTypes.object,
    locations_line_items: PropTypes.arrayOf(PropTypes.object),
    areSizeQuantitiesSet: PropTypes.bool,
    customer_supplied: PropTypes.bool,
    utees: PropTypes.bool,
    allLocationsNonstandard: PropTypes.bool,
  };

  static defaultProps = {
    areSizeQuantitiesSet: false,
    none: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      product_id:
        this.props.product && this.props.product.id
          ? this.props.product.id.toString()
          : null,
      product: this.props.product || null,
      customer_supplied: this.props.customer_supplied || false,
      none: this.props.none || false,
      individual_ship: this.props.individual_ship || false,
      individual_payment: this.props.individual_payment || false,
      bag_tag: this.props.bag_tag || false,
      pick_pack_ship: this.props.pick_pack_ship || false,
      poly_bag: this.props.poly_bag || false,
      split_ship: this.props.split_ship || false,
      temporary_storage: this.props.temporary_storage || false,
      colorOptions: [],
      color: this.props.color
        ? {
            value: this.props.color.id,
            label: this.props.color.name,
          }
        : null,
      color_id: this.props.color ? this.props.color.id.toString() : null,
      selectedColor: this.props.color ? this.props.color.id.toString() : null,
      custom_item: this.props.product ? this.props.product.custom_item : null,
      bird_bank: this.props.bird_bank || false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBirdBankChange = this.handleBirdBankChange.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  componentWillMount() {
    this.fetchColors(this.props.product_id);
  }

  prefix_input(name) {
    return this.props.prefix + '[' + name + ']';
  }

  handleChange(name, e) {
    const { checked } = e.target;
    this.setState({ [name]: checked });
  }

  handleBirdBankChange(name, e) {
    let canSetIndividualPaymentDefault =
      e.target.value === '1' && this.props.individual_payment !== false;

    if (canSetIndividualPaymentDefault) {
      this.setState({ individual_payment: true });
    }

    const { checked } = e.target;
    this.setState({ [name]: checked });
  }

  handleOptionChange(name, event) {
    let opts = [
      'bag_tag',
      'individual_ship',
      'none',
      'pick_pack_ship',
      'poly_bag',
      'split_ship',
      'temporary_storage',
    ];

    let fulfillmentOptions = {};

    opts.forEach(opt => {
      fulfillmentOptions[opt] =
        event.target.id === this.props.prefix + '[' + opt + ']';
    });

    this.setState(fulfillmentOptions);
  }

  fetchColors(product_id) {
    if (!product_id) {
      return;
    }

    const endpoint = `/api/v1/print_colors?product_id=${product_id}`;
    localGet(endpoint).then(data => {
      const colorOptions = data.map((color, index) => ({
        value: color.id.toString(),
        label: color.name,
        hex_code: color.hex_code ? color.hex_code.toString() : '',
        index: index,
      }));

      this.setState({ colorOptions });
    });
  }

  handleProductSelect = product_id => {
    this.setState({
      product_id: product_id,
      color_id: null,
      color: null,
      selectedColor: null,
      colorOptions: [],
    });

    if (product_id) {
      const endpoint = `/api/v1/print_colors?product_id=${product_id}`;

      localGet(endpoint).then(data => {
        const colorOptions = data.map((color, index) => ({
          value: color.id.toString(),
          label: color.name,
          hex_code: color.hex_code ? color.hex_code.toString() : '',
          outOfStock: color.out_of_stock,
          disabled:
            !this.props.outOfStockItemSelectionEnabled && color.out_of_stock,
          index: index,
        }));

        this.setState({ colorOptions });
      });
    }
  };

  onColorChange = selectedColorValues => {
    let values = selectedColorValues.split(',');
    this.setState(previousState => {
      return {
        selectedColor: difference(values, [previousState.selectedColor])[0],
      };
    });
  };

  renderColor(_options, option) {
    return (
      <>
        <div
          className="color-preview"
          style={{ backgroundColor: '#' + option.hex_code }}
        />
        <span
          className={classNames({ 'disabled-admin-view': option.outOfStock })}
        >
          {option.label}
        </span>
        {option.outOfStock && (
          <span className="txt-small txt-muted2"> - Out of Stock</span>
        )}
      </>
    );
  }

  renderOption(_options, option) {
    return (
      <>
        <span
          className={classNames({ 'disabled-admin-view': option.outOfStock })}
        >
          {option.label}
        </span>
        {option.productObject.campus_core && (
          <span className="txt-small txt-muted2"> - Campus Core</span>
        )}
        {option.outOfStock && (
          <span className="txt-small txt-muted2"> - Out of Stock</span>
        )}
      </>
    );
  }

  productSelect() {
    let options = GlobalData.productOptions.filter(
      option => !option.productObject.custom_item
    );

    return (
      <div id={`location-product-select-${this.props.index}`}>
        <Select
          name={this.props.prefix + '[product_id]'}
          options={options}
          optionRenderer={this.renderOption.bind(this, options)}
          matchProp="label"
          className="top-apparel-item-select"
          placeholder="Select an Item"
          onChange={this.handleProductSelect}
          value={this.state.product_id}
          valueRenderer={this.renderOption.bind(this, options)}
          simpleValue={true}
        />
      </div>
    );
  }

  colorSelect() {
    let { custom_item, colorOptions, selectedColor } = this.state;
    let options = custom_item ? GlobalData.allColorOptions : colorOptions;

    return (
      <div id={`location-color-select-${this.props.index}`}>
        <Select
          name={this.props.prefix + '[color_id]'}
          options={options}
          optionRenderer={this.renderColor.bind(this, options)}
          onChange={this.onColorChange}
          valueRenderer={this.renderColor.bind(this, options)}
          value={selectedColor}
          className="color-select"
          multi={true}
          allowCreate={false}
          ref={c => {
            this.itemFields = c;
          }}
          addLabelText="Other: {label}"
          placeholder="Select Color"
          simpleValue={true}
        />
      </div>
    );
  }

  customCheckbox(options) {
    const showCustomCheckbox =
      intersection(GlobalData.currentUserRoles, [
        'uberadmin',
        'admin',
        'business_development_leader',
        'artist',
        'account_executive',
      ]).length > 0;

    if (
      !this.state.custom_item &&
      this.props.product &&
      this.props.product.id
    ) {
      return;
    } else if (showCustomCheckbox) {
      let props = {
        readOnly: false,
        ...options,
      };

      return (
        <div className="notification notification--notice notification--small ptm prm pbm plm">
          <div className="mbm">
            <p>
              Can&apos;t find the item you need?{' '}
              <strong>Select Custom Item.</strong>
            </p>
            <CheckBoxField
              id="custom_item"
              name={this.props.prefix + '[product_attributes][custom_item]'}
              checked={this.state.custom_item}
              label="Custom Item"
              onChange={this.toggleCustom}
              {...props}
            />
          </div>
        </div>
      );
    } else if (!this.props.product) {
      return (
        <p>
          If the item you&apos;re looking for is unavailable, please contact
          your BDL.
        </p>
      );
    }
  }

  itemDetails() {
    let matched_product = find(GlobalData.productOptions, {
      value: this.state.product_id,
    });

    let fields = this.state.custom_item ? (
      <CustomItemSelect
        prefix={this.props.prefix}
        rolesCanEditPostCheckout={this.rolesCanEditPostCheckout()}
        rolesCanEditPreCheckout={this.rolesCanEditPreCheckout()}
        product={this.props.product}
        order={this.props.order}
        color={this.props.color}
      />
    ) : this.rolesCanEditPostCheckout() ? (
      this.productSelect()
    ) : (
      <div className="line-item-style-wrapper">
        <strong>Style</strong>
        <p className="mtn">{matched_product.label}</p>
      </div>
    );
    return fields;
  }

  topItemFields() {
    if (this.state.custom_item) return;
    return (
      <div>
        {this.productSelect()}

        {this.colorSelect()}
      </div>
    );
  }

  toggleCustom = e => {
    this.setState({ custom_item: e.target.checked });
  };

  rolesCanEditPreCheckout() {
    const roles = [
      'uberadmin',
      'admin',
      'business_development_leader',
      'account_executive',
    ];
    return intersection(GlobalData.currentUserRoles, roles).length > 0;
  }

  businessRolesCanEditOpsPreCheckout() {
    const roles = ['uberadmin', 'admin', 'account_executive'];
    return intersection(GlobalData.currentUserRoles, roles).length > 0;
  }

  canSeeRequestBestPriceButton() {
    const roles = ['uberadmin', 'admin', 'business_development_leader'];
    return intersection(GlobalData.currentUserRoles, roles).length > 0;
  }

  rolesCanEditPostCheckout() {
    const { order } = this.props;
    const roles = ['uberadmin', 'admin'];
    return (
      order.isPostCheckout &&
      intersection(GlobalData.currentUserRoles, roles).length > 0
    );
  }

  canEditColorPreCheckout() {
    const { order } = this.props;
    const roles = ['uberadmin', 'artist'];
    return (
      !order.isPostCheckout &&
      intersection(GlobalData.currentUserRoles, roles).length > 0
    );
  }

  editBirdBankCheckBox() {
    return (
      <div>
        <CheckBoxField
          id="bird_bank"
          className="pts"
          name={this.props.prefix + '[bird_bank]'}
          label="Bird Bank"
          checked={this.props.bird_bank}
          readOnly={this.props['added_to_bird_bank?']}
          onChange={this.handleBirdBankChange.bind(this, 'bird_bank')}
        />
      </div>
    );
  }

  editIndividualPaymentCheckBox() {
    if (this.state.bird_bank) {
      return (
        <div className="mll">
          <CheckBoxField
            id="individual_payment"
            className="pts"
            name={this.props.prefix + '[individual_payment]'}
            label="Collect Individual Payments"
            checked={this.state.individual_payment && this.state.bird_bank}
            readOnly={this.props['added_to_bird_bank?']}
            onChange={this.handleChange.bind(this, 'individual_payment')}
          />
        </div>
      );
    } else {
      return (
        <input
          type="hidden"
          name={this.props.prefix + '[individual_payment]'}
          value="0"
        />
      );
    }
  }

  toggleReceivable = e => {
    this.setState({ customer_supplied: e.target.checked });
  };

  customerApparelProvided(options) {
    const { order } = this.props;
    const showCheckbox =
      intersection(GlobalData.currentUserRoles, [
        'uberadmin',
        'admin',
        'sales_rep',
      ]).length > 0;

    if (showCheckbox && order.formIsExposable) {
      let props = {
        readOnly: false,
        ...options,
      };
      return (
        <div className="ptl">
          <CheckBoxField
            className="pts"
            name={this.props.prefix + '[customer_supplied]'}
            label="Customer Supplied"
            checked={this.state.customer_supplied}
            onChange={this.toggleReceivable}
            {...props}
          />
        </div>
      );
    }
  }

  render() {
    let {
      id,
      art_job,
      globalData,
      art_submitted,
      order,
      areSizeQuantitiesSet,
    } = this.props;

    let fields;

    let editableOption;

    if (order.utees) {
      editableOption = !order.isPostCheckout || this.rolesCanEditPostCheckout();
    } else {
      editableOption =
        (!order.isPostCheckout && this.businessRolesCanEditOpsPreCheckout()) ||
        this.rolesCanEditPostCheckout();
    }

    let fulfillmentDisabled;
    if (GlobalData.canOverrideBirdBankShippingMethod) {
      fulfillmentDisabled = false;
    } else {
      fulfillmentDisabled =
        this.props['individual_ship'] && this.props['added_to_bird_bank?'];
    }

    let checkBoxFields, financialFields;

    let noneOption = (
      <RadioButtonOption
        id={this.props.prefix + '[none]'}
        name={this.props.prefix + '[none]'}
        label="None"
        checked={this.state.none}
        onChange={this.handleOptionChange.bind(this, 'none')}
        className={editableOption ? '' : 'readOnly'}
        disabled={fulfillmentDisabled}
      />
    );

    let bagTagOption = (
      <RadioButtonOption
        id={this.props.prefix + '[bag_tag]'}
        name={this.props.prefix + '[bag_tag]'}
        label="Bag & Tag"
        checked={this.state.bag_tag}
        onChange={this.handleOptionChange.bind(this, 'bag_tag')}
        className={editableOption ? '' : 'readOnly'}
        disabled={fulfillmentDisabled}
      />
    );

    let indShipOption = (
      <RadioButtonOption
        id={this.props.prefix + '[individual_ship]'}
        name={this.props.prefix + '[individual_ship]'}
        label="Individual Ship"
        checked={this.state.individual_ship}
        onChange={this.handleOptionChange.bind(this, 'individual_ship')}
        className={editableOption ? '' : 'readOnly'}
        disabled={
          this.props['added_to_bird_bank?'] &&
          !GlobalData.canOverrideBirdBankShippingMethod
        }
      />
    );

    let pickPackShipOption = (
      <RadioButtonOption
        id={this.props.prefix + '[pick_pack_ship]'}
        name={this.props.prefix + '[pick_pack_ship]'}
        label="Pick, Pack & Ship"
        checked={this.state.pick_pack_ship}
        onChange={this.handleOptionChange.bind(this, 'pick_pack_ship')}
        className={editableOption ? '' : 'readOnly'}
        disabled={this.props['added_to_bird_bank?']}
      />
    );

    let polyBagOption = (
      <RadioButtonOption
        id={this.props.prefix + '[poly_bag]'}
        name={this.props.prefix + '[poly_bag]'}
        label="Poly Bag"
        checked={this.state.poly_bag}
        onChange={this.handleOptionChange.bind(this, 'poly_bag')}
        className={editableOption ? '' : 'readOnly'}
        disabled={this.props['added_to_bird_bank?']}
      />
    );

    let splitShipOption = (
      <RadioButtonOption
        id={this.props.prefix + '[split_ship]'}
        name={this.props.prefix + '[split_ship]'}
        label="Split Ship"
        checked={this.state.split_ship}
        onChange={this.handleOptionChange.bind(this, 'split_ship')}
        className={editableOption ? '' : 'readOnly'}
        disabled={this.props['added_to_bird_bank?']}
      />
    );

    let temporaryStorage = (
      <RadioButtonOption
        id={this.props.prefix + '[temporary_storage]'}
        name={this.props.prefix + '[temporary_storage]'}
        label="Temporary Storage"
        checked={this.state.temporary_storage}
        onChange={this.handleOptionChange.bind(this, 'temporary_storage')}
        className={editableOption ? '' : 'readOnly'}
        disabled={this.props['added_to_bird_bank?']}
      />
    );

    let foldingOption = (
      <div>
        <CheckBoxField
          id={this.props.prefix + '[folding]'}
          name={this.props.prefix + '[folding]'}
          label="Folding"
          checked={this.props.folding}
          onChange={this.handleChange.bind(this, 'folding')}
          className={editableOption ? '' : 'readOnly'}
        />
      </div>
    );

    let hangtagsOption = (
      <div>
        <CheckBoxField
          id={this.props.prefix + '[hang_tags]'}
          name={this.props.prefix + '[hang_tags]'}
          label="Hang Tags"
          checked={this.props.hang_tags}
          onChange={this.handleChange.bind(this, 'hang_tags')}
          className={editableOption ? '' : 'readOnly'}
        />
      </div>
    );

    let hangingOption = (
      <div>
        <CheckBoxField
          id={this.props.prefix + '[hanging]'}
          name={this.props.prefix + '[hanging]'}
          label="Hanging"
          checked={this.props.hanging}
          onChange={this.handleChange.bind(this, 'hanging')}
          className={editableOption ? '' : 'readOnly'}
        />
      </div>
    );

    let heatTaggingReadOnly =
      intersection(globalData.currentUserRoles, [
        'admin',
        'business_development_leader',
        'uberadmin',
        'account_executive',
      ]).length === 0;

    let heatTaggingOption = (
      <div>
        <CheckBoxField
          id={this.props.prefix + '[heat_tagging]'}
          name={this.props.prefix + '[heat_tagging]'}
          label="Brand Tagging"
          checked={this.props.heat_tagging}
          onChange={this.handleChange.bind(this, 'heat_tagging')}
          className={editableOption ? '' : 'readOnly'}
          readOnly={heatTaggingReadOnly}
        />
      </div>
    );

    let kittingOption = (
      <div>
        <CheckBoxField
          id={this.props.prefix + '[kitting]'}
          name={this.props.prefix + '[kitting]'}
          label="Kitting"
          checked={this.props.kitting}
          onChange={this.handleChange.bind(this, 'kitting')}
          className={editableOption ? '' : 'readOnly'}
        />
      </div>
    );

    let marketingInsertsOption = (
      <div>
        <CheckBoxField
          id={this.props.prefix + '[marketing_inserts]'}
          name={this.props.prefix + '[marketing_inserts]'}
          label="Marketing Inserts"
          checked={this.props.marketing_inserts}
          onChange={this.handleChange.bind(this, 'marketing_inserts')}
          className={editableOption ? '' : 'readOnly'}
        />
      </div>
    );

    let sewnTagsOption = (
      <div>
        <CheckBoxField
          id={this.props.prefix + '[sewn_tags]'}
          name={this.props.prefix + '[sewn_tags]'}
          label="Sewn Tags"
          checked={this.props.sewn_tags}
          onChange={this.handleChange.bind(this, 'sewn_tags')}
          className={editableOption ? '' : 'readOnly'}
        />
      </div>
    );

    let stickersOption = (
      <div>
        <CheckBoxField
          id={this.props.prefix + '[stickers]'}
          name={this.props.prefix + '[stickers]'}
          label="Stickers"
          checked={this.props.stickers}
          onChange={this.handleChange.bind(this, 'stickers')}
          className={editableOption ? '' : 'readOnly'}
        />
      </div>
    );

    let tagRemovalOption = (
      <div>
        <CheckBoxField
          id={this.props.prefix + '[tag_removal]'}
          name={this.props.prefix + '[tag_removal]'}
          label="Tag Removal"
          checked={this.props.tag_removal}
          onChange={this.handleChange.bind(this, 'tag_removal')}
          className={editableOption ? '' : 'readOnly'}
        />
      </div>
    );

    if (GlobalData.canEditFinancials) {
      financialFields = (
        <div>
          <h4 className="mbs">Financial Options:</h4>
          <CheckBoxField
            id={this.props.prefix + '[non_commissionable]'}
            name={this.props.prefix + '[non_commissionable]'}
            label="Non Commissionable"
            checked={this.props.non_commissionable}
            onChange={this.handleChange.bind(this, 'non_commissionable')}
          />
        </div>
      );
    }

    if (this.props.order.utees) {
      checkBoxFields = (
        <div>
          <h4 className="mbs">Packaging Options:</h4>
          {GlobalData.canOverrideBirdBankShippingMethod &&
            this.props['added_to_bird_bank?'] && (
              <div className="notification notification--compact notification--support">
                <p>
                  <strong>Warning!</strong> Normally editing isn't allowed, but
                  you have special permissions as a support user. Proceed with
                  caution.
                </p>
              </div>
            )}
          {noneOption}
          {bagTagOption}
          {indShipOption}

          <h4 className="mbs">Finishing Options:</h4>
          {heatTaggingOption}
        </div>
      );
    } else if (this.props.order.corporate) {
      checkBoxFields = (
        <div>
          <h4 className="mbs">Packaging Options:</h4>
          {noneOption}
          {bagTagOption}
          {indShipOption}
          {pickPackShipOption}
          {polyBagOption}
          {splitShipOption}
          {temporaryStorage}

          <h4 className="mbs">Finishing Options:</h4>
          {heatTaggingOption}
          {foldingOption}
          {hangtagsOption}
          {hangingOption}
          {kittingOption}
          {marketingInsertsOption}
          {sewnTagsOption}
          {stickersOption}
          {tagRemovalOption}
        </div>
      );
    } else {
      checkBoxFields = (
        <div>
          <h4 className="mbs">Packaging Options:</h4>
          {noneOption}
          {bagTagOption}
          {indShipOption}
          {pickPackShipOption}
          {polyBagOption}
          {splitShipOption}
          {temporaryStorage}

          <h4 className="mbs">Finishing Options:</h4>
          {foldingOption}
          {hangtagsOption}
          {hangingOption}
          {kittingOption}
          {marketingInsertsOption}
          {sewnTagsOption}
          {stickersOption}
          {tagRemovalOption}
        </div>
      );
    }

    let customItemSelectRender = this.state.custom_item ? (
      <CustomItemSelect
        prefix={this.props.prefix}
        rolesCanEditPostCheckout={this.rolesCanEditPostCheckout()}
        rolesCanEditPreCheckout={this.rolesCanEditPreCheckout()}
        customItem={this.state.custom_item}
        product={this.props.product}
        order={this.props.order}
        color={this.props.color}
      />
    ) : null;

    if (art_submitted && id) {
      let matched_color = find(this.state.colorOptions, {
        value: this.state.selectedColor,
      });
      let hex_code, color_name;
      let edit_state = !order.isPostCheckout;

      if (matched_color) {
        hex_code = matched_color.hex_code;
        color_name = matched_color.label;
      } else {
        color_name = this.state.custom_color;
      }
      const showPrintColorFields = !this.props.allLocationsNonstandard;

      let editInkColors = () => {
        if (art_job.print_color_change && showPrintColorFields) {
          return (
            <div className="callout callout--white">
              <h3 className="mbs mtn">Edit Decoration Colors</h3>
              <PrintColorFields
                prefix={this.props.prefix}
                locations_line_items={this.props.locations_line_items}
                order_id={order.id}
              />
            </div>
          );
        } else {
          return null;
        }
      };

      let colorBlock;

      if (this.state.custom_item) {
        colorBlock = null;
      } else if (
        this.canEditColorPreCheckout() ||
        this.rolesCanEditPostCheckout()
      )
        colorBlock = this.colorSelect();
      else {
        colorBlock = (
          <div className="mbl">
            <strong>Color:</strong>
            <div className="inline-block">
              <div
                className="color-preview"
                style={{ backgroundColor: '#' + hex_code }}
              />
              {color_name}
            </div>
          </div>
        );
      }

      let sizeSetMessage;

      if (areSizeQuantitiesSet) {
        if (this.props['added_to_bird_bank?']) {
          sizeSetMessage = (
            <small className="txt-muted">
              <em>
                Quantities may not be changed for items on a closed Bird Bank.
              </em>
            </small>
          );
        } else {
          sizeSetMessage = (
            <small className="txt-muted">
              <em>
                Size quantities have already been set. If you want to update the
                quantity, update the size breakdown instead.
              </em>
            </small>
          );
        }
      }

      if (edit_state) {
        fields = (
          <div>
            <div className="mts">{this.customCheckbox({ readOnly: true })}</div>

            {this.itemDetails()}

            {colorBlock}

            {sizeSetMessage}

            <div>
              <label htmlFor="quantity">Quantity</label>
              <input
                type="number"
                id="quantity"
                name={this.props.prefix + '[quantity]'}
                defaultValue={this.props.quantity}
                placeholder="Quantity"
                disabled={areSizeQuantitiesSet}
                className={classNames({
                  readonly: areSizeQuantitiesSet,
                })}
                ref={c => {
                  this.quantity = c;
                }}
              />
            </div>

            {GlobalData.canAddFreeItems && (
              <div>
                <label htmlFor="free_items">Additional Free Items</label>
                <input
                  type="number"
                  id="free_items"
                  name={this.props.prefix + '[free_items]'}
                  defaultValue={this.props.free_items || 0}
                  placeholder="Additional Free Items"
                  ref={c => {
                    this.free_items = c;
                  }}
                />
              </div>
            )}

            {editInkColors()}

            <div className="mtl">
              {this.customerApparelProvided({ readOnly: false })}
            </div>
          </div>
        );
      } else {
        let rolesThatMayEditInk = ['admin', 'uberadmin', 'art_allocator'];

        let canEditInkColors =
          globalData &&
          globalData.currentUserRoles &&
          containsAtLeastOne(globalData.currentUserRoles, rolesThatMayEditInk);

        fields = (
          <div>
            <div className="mtl">{this.customCheckbox({ readOnly: true })}</div>
            {this.itemDetails()}
            {colorBlock}
            <p>
              <strong>Quantity:</strong> {this.props.quantity}
            </p>
            {canEditInkColors ? editInkColors() : null}
            <div className="mtl">
              {this.customerApparelProvided({ readOnly: false })}
            </div>
          </div>
        );
      }
    } else {
      fields = (
        <div>
          {this.topItemFields()}

          {this.customCheckbox({ readOnly: false })}

          {customItemSelectRender}

          {areSizeQuantitiesSet && (
            <small className="txt-muted">
              <em>
                Size quantities have already been set. If you want to update the
                quantity, please update the size breakdown.
              </em>
            </small>
          )}

          <div>
            <label htmlFor="quantity">Quantity</label>
            <input
              type="number"
              id="quantity"
              name={this.props.prefix + '[quantity]'}
              defaultValue={this.props.quantity}
              placeholder="Quantity"
              disabled={areSizeQuantitiesSet ? 'disabled' : ''}
              className={areSizeQuantitiesSet ? 'readonly' : ''}
              ref={c => {
                this.quantity = c;
              }}
            />
          </div>

          {GlobalData.canAddFreeItems && (
            <div>
              <label htmlFor="free_items">Additional Free Items</label>
              <input
                type="number"
                id="free_items"
                name={this.props.prefix + '[free_items]'}
                defaultValue={this.props.free_items || 0}
                placeholder="Additional Free Items"
                ref={c => {
                  this.free_items = c;
                }}
              />
            </div>
          )}

          {this.customerApparelProvided()}

          {this.props.bird_bank}
        </div>
      );
    }

    return (
      <div className="form-full form-vertical bulk-edit-line-item">
        <fieldset>
          <input
            type="hidden"
            name={this.prefix_input('description')}
            value="blank"
          />
          <input
            type="hidden"
            name={this.prefix_input('id')}
            value={this.props.id}
          />
          <input
            type="hidden"
            name={this.prefix_input('art_job_id')}
            value={this.props.art_job_id}
          />

          {fields}

          {this.editBirdBankCheckBox()}

          {this.editIndividualPaymentCheckBox()}

          {checkBoxFields}

          {financialFields}
        </fieldset>
      </div>
    );
  }
}

export default LineItemFields;
